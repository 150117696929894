import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import type { To } from 'react-router-dom';

import type {
  ManualTaskFinishedEvent,
  ManualTaskSuspendedEvent,
  ProcessInstanceTerminatedEvent,
  TaskViewConfig,
  UserTaskFinishedEvent,
  UserTaskSuspendedEvent,
} from '@atlas-engine-contrib/atlas-ui_contracts';
import { DataModels, Messages, Subscription } from '@atlas-engine/atlas_engine_sdk';

import { AnyTaskType, EngineService, RouteComponentProps, withEngineService, withRouter } from '../../../lib/index';
import { TaskView } from './TaskView';
import { GenericViewProps } from '../../GenericViewProps';
import type { LanguageService } from '../../../lib/LanguageService';
import { Layout, LayoutContent, LayoutHeader } from '../../Layout';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import LoadingSpinner from '../../components/LoadingSpinner';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import Alert from '../../components/Alert';

type RoutedTaskViewProps = {
  taskViewConfig: TaskViewConfig;
  languageService: LanguageService;
  engineService?: EngineService;
} & GenericViewProps &
  RouteComponentProps<keyof TaskViewParameters> &
  WithTranslation;

type TaskViewParameters = {
  correlationId: string;
  processInstanceId: string;
  flowNodeInstanceId: string;
};

class TaskViewWrapper extends React.Component<RoutedTaskViewProps, any> {
  private currentCorrelationId = this.props.router.params.correlationId ?? '';
  private currentProcessInstanceId = this.props.router.params.processInstanceId ?? '';
  private currentFlowNodeInstanceId = this.props.router.params.flowNodeInstanceId ?? '';

  private metadataChangedSubscription?: Subscription;
  private correlationProgressSubscriptions?: Subscription[];
  private correlationStateSubscriptions?: Subscription[];

  private engineService!: EngineService;

  private taskFinished = false;
  private getNextTaskCallRequired = false;
  private getNextTaskInProgress = false;
  private loadingSpinnerRef: React.RefObject<typeof HTMLSpanElement>;
  private didMount = false;

  constructor(props: RoutedTaskViewProps) {
    super(props);

    this.loadingSpinnerRef = React.createRef();

    if (this.props.engineService) {
      this.engineService = this.props.engineService;
    }

    this.state = {
      isLoading: true,
      showBackdrop: false,
      showLoadingSpinnerOnInitialRender:
        this.props.router.location.state?.loadingSpinnerActive === true || props.loadingSpinnerActiveOnInitialAccess,
      progressViewTransition: this.props.router.location.state?.progressViewTransition === true,
    };
  }

  componentDidMount(): void {
    this.didMount = true;
    this.createProcessInstanceMetadataChangedSubscription();
    this.createCorrelationSubscriptions();
    if (this.props.router.location.state?.progressViewTransition == null) {
      this.determineProgressView();
    }
    this.getTaskForFlowNodeInstance(this.currentFlowNodeInstanceId);
  }

  async componentDidUpdate(
    prevProps: Readonly<RoutedTaskViewProps>,
    prevState: Readonly<any>,
    snapshot?: any
  ): Promise<void> {
    if (
      this.props.router.navigationType === 'POP' &&
      (prevProps.router.params.flowNodeInstanceId !== this.props.router.params.flowNodeInstanceId ||
        prevProps.router.params.processInstanceId !== this.props.router.params.processInstanceId)
    ) {
      this.currentCorrelationId = this.props.router.params.correlationId ?? '';
      this.currentFlowNodeInstanceId = this.props.router.params.flowNodeInstanceId ?? '';
      this.currentProcessInstanceId = this.props.router.params.processInstanceId ?? '';
      this.setState({ isLoading: true }, async () => {
        await this.getTaskForFlowNodeInstance(this.currentFlowNodeInstanceId);
        if (this.metadataChangedSubscription) {
          await this.engineService.removeSubscriptions([this.metadataChangedSubscription]);
          this.createProcessInstanceMetadataChangedSubscription();
        }
        this.determineProgressView();
      });
    }
    if (this.state.currentTask && this.state.currentTask === prevState.nextTask) {
      this.currentCorrelationId = this.state.currentTask.correlationId;
      this.currentFlowNodeInstanceId = this.state.currentTask.flowNodeInstanceId;
      this.currentProcessInstanceId = this.state.currentTask.processInstanceId;

      if (
        this.currentProcessInstanceId !== prevState.currentTask.processInstanceId &&
        this.metadataChangedSubscription
      ) {
        await this.engineService.removeSubscriptions([this.metadataChangedSubscription]);
        this.createProcessInstanceMetadataChangedSubscription();
        this.determineProgressView();
      }
    }
  }

  async componentWillUnmount(): Promise<void> {
    if (this.metadataChangedSubscription) {
      await this.engineService.removeSubscriptions([this.metadataChangedSubscription]);
    }
    if (this.correlationProgressSubscriptions) {
      await this.engineService.removeSubscriptions(this.correlationProgressSubscriptions);
    }
    if (this.correlationStateSubscriptions) {
      await this.engineService.removeSubscriptions(this.correlationStateSubscriptions);
    }
  }

  render(): JSX.Element {
    const loadingSpinnerComponent = (
      <DelayedRenderer timeoutInMs={this.state.showLoadingSpinnerOnInitialRender ? 0 : !this.didMount ? 800 : 300}>
        <LoadingSpinner
          htmlRef={this.loadingSpinnerRef}
          style={{ gridArea: 'content' }}
          backdrop={this.state.showBackdrop}
        />
      </DelayedRenderer>
    );

    return (
      <Layout>
        <LayoutHeader logo={this.props.logo} />
        {this.state.isLoading && loadingSpinnerComponent}
        <LayoutContent>
          <div className={`task-view task-view--${this.state.currentTask?.flowNodeId?.trim().replaceAll(' ', '-')}`}>
            <div className="task-view__content">
              {this.state.error && <ErrorRenderer error={this.state.error} />}
              {!this.state.error && this.renderContent()}
            </div>
          </div>
        </LayoutContent>
      </Layout>
    );
  }

  private renderContent(): JSX.Element | null {
    const taskWasNotFound = !this.state.currentTask && !this.state.isLoading;

    if (taskWasNotFound) {
      const overviewLink = this.state.progressViewTransition
        ? '/task-list'
        : `/correlation/${this.currentCorrelationId}`;
      return (
        <Alert variant="warning">
          <Alert.Heading>{this.props.t('TaskView.TaskNotFound')}</Alert.Heading>
          <Alert.Body>
            <p className="mt-4">
              <Alert.Link href={overviewLink}>{this.props.t('TaskView.SwitchToOverview')}</Alert.Link>
            </p>
          </Alert.Body>
        </Alert>
      );
    }

    return (
      <Fragment>
        {this.state.currentTask && (
          <span className="h-full" key={this.state.currentTask.flowNodeInstanceId}>
            <TaskView
              task={this.state.currentTask}
              engineService={this.engineService}
              taskViewConfig={this.props.taskViewConfig}
              languageService={this.props.languageService}
              onReady={this.onTaskViewReady}
              onTaskFinished={this.onTaskFinished}
              onTaskSuspended={this.onTaskSuspended}
              onProcessInstanceTerminated={this.onProcessInstanceTerminated}
              onBusy={this.onBusy}
            ></TaskView>
          </span>
        )}

        {this.state.nextTask && (
          <span className="h-full fixed -left-full" key={this.state.nextTask.flowNodeInstanceId}>
            <TaskView
              task={this.state.nextTask}
              engineService={this.engineService}
              taskViewConfig={this.props.taskViewConfig}
              languageService={this.props.languageService}
              onReady={this.onTaskViewReady}
              onTaskFinished={this.onTaskFinished}
              onTaskSuspended={this.onTaskSuspended}
              onProcessInstanceTerminated={this.onProcessInstanceTerminated}
              onBusy={this.onBusy}
            ></TaskView>
          </span>
        )}
      </Fragment>
    );
  }

  private onTaskViewReady = () => {
    const stateToUpdate: any = {
      isLoading: false,
      showBackdrop: false,
    };

    if (this.state.currentTask && this.state.nextTask) {
      stateToUpdate.currentTask = this.state.nextTask;
      stateToUpdate.nextTask = null;
    }

    this.setState(stateToUpdate, () => {
      this.engineService.userMetadataStorage.set('portal:flowNodeInstance:seen', true, {
        flowNodeInstanceScope: this.state.currentTask.flowNodeInstanceId,
      });
    });
  };

  private onTaskFinished = (event: ManualTaskFinishedEvent | UserTaskFinishedEvent, task: AnyTaskType) => {
    if (!this.state.progressViewTransition) {
      this.navigate(`/correlation/${task.correlationId}`, {
        processInstanceId: task.processInstanceId,
      });
      return;
    }
    event.preventDefault();
    this.taskFinished = true;

    if (this.getNextTaskInProgress) {
      return;
    }
    this.getNextTask();
  };

  private onTaskSuspended = (event: ManualTaskSuspendedEvent | UserTaskSuspendedEvent | Event, task: AnyTaskType) => {
    if (this.state.progressViewTransition) {
      return this.navigate({ pathname: '/task-list', hash: `#task-${task.flowNodeInstanceId}` });
    }
    this.navigate(`/correlation/${task?.correlationId}?autoNavigate=false`, {
      processInstanceId: task?.processInstanceId,
    });
  };

  private onProcessInstanceTerminated = async (event: ProcessInstanceTerminatedEvent) => {
    const correlation = await this.engineService.getCorrelation(this.currentCorrelationId);
    const processInstance = correlation.processInstances?.find(
      (instance) => event.processInstanceId === instance.processInstanceId
    );
    const processInstanceReturnToStartDialogMetadata = processInstance?.metadata?.returnToStartDialog;

    if (processInstanceReturnToStartDialogMetadata) {
      const parsedUrl = new URL(`${window.location.origin}/startdialog/${processInstanceReturnToStartDialogMetadata}`);

      return this.navigate({
        pathname: parsedUrl.pathname,
        search: parsedUrl.searchParams.toString(),
      });
    }

    const correlationIsFinished = correlation?.processInstances?.every(
      (e: any) =>
        e.state === DataModels.ProcessInstances.ProcessInstanceState.finished ||
        e.state === DataModels.ProcessInstances.ProcessInstanceState.terminated
    );

    if (correlationIsFinished) {
      return this.handleCorrelationFinishedNavigation(correlation);
    }

    this.navigate('/');
  };

  private onBusy = () => {
    this.setState({ isLoading: true, showBackdrop: true, showLoadingSpinnerOnInitialRender: false });
  };

  private async getTaskForFlowNodeInstance(flowNodeInstanceId: string): Promise<void> {
    try {
      const task =
        this.props.router.location.state?.task ||
        (await this.engineService.getTaskByFlowNodeInstanceId(flowNodeInstanceId));
      this.setState({
        currentTask: task,
        isLoading: task == null ? false : this.state.isLoading,
      });
    } catch (error) {
      this.setState({ error: error, isLoading: false });
    }
  }

  private createProcessInstanceMetadataChangedSubscription(): void {
    this.engineService
      ?.onProcessInstanceMetadataChanged(this.currentProcessInstanceId, ((message) => {
        this.setState({
          progressViewTransition: message.changedMetadata.progressView?.toLowerCase() !== 'correlation',
        });
      }) as Messages.CallbackTypes.OnProcessInstanceMetadataChangedCallback)
      .then((subscription: Subscription) => (this.metadataChangedSubscription = subscription));
  }

  private createCorrelationSubscriptions(): void {
    this.engineService
      ?.onNewTaskWaiting(this.currentCorrelationId, async (message) => {
        if (!this.taskFinished) {
          return;
        }

        if (this.getNextTaskInProgress) {
          this.getNextTaskCallRequired = true;
        } else {
          this.getNextTask(message);
        }
      })
      .then((subscriptions) => (this.correlationProgressSubscriptions = subscriptions));

    this.engineService
      ?.onCorrelationStateChanged(this.currentCorrelationId, (correlation) => {
        const processInstance = correlation.processInstances?.find(
          (instance) => instance.processInstanceId === this.state.currentTask?.processInstanceId
        );
        const processInstanceIsFinished =
          processInstance?.state === DataModels.ProcessInstances.ProcessInstanceState.finished ||
          processInstance?.state === DataModels.ProcessInstances.ProcessInstanceState.terminated;
        const processInstanceReturnToStartDialogMetadata = processInstance?.metadata?.returnToStartDialog;

        if (processInstanceIsFinished && processInstanceReturnToStartDialogMetadata) {
          const parsedUrl = new URL(
            `${window.location.origin}/startdialog/${processInstanceReturnToStartDialogMetadata}`
          );

          return this.navigate({
            pathname: parsedUrl.pathname,
            search: parsedUrl.searchParams.toString(),
          });
        }

        const correlationIsFinished = correlation?.processInstances?.every(
          (e: any) =>
            e.state === DataModels.ProcessInstances.ProcessInstanceState.finished ||
            e.state === DataModels.ProcessInstances.ProcessInstanceState.terminated
        );

        if (correlationIsFinished) {
          return this.handleCorrelationFinishedNavigation(correlation);
        }
      })
      .then((subscriptions) => (this.correlationStateSubscriptions = subscriptions));
  }

  private handleCorrelationFinishedNavigation(correlation: DataModels.Correlation.Correlation): void {
    const returnToStartDialog = correlation?.metadata?.returnToStartDialog;

    if (returnToStartDialog) {
      const parsedUrl = new URL(`${window.location.origin}/startdialog/${returnToStartDialog}`);
      parsedUrl.searchParams.set('lastActiveCorrelation', `${correlation?.correlationId}`);

      return this.navigate({
        pathname: parsedUrl.pathname,
        search: parsedUrl.searchParams.toString(),
      });
    }

    const searchParams = new URLSearchParams(`lastActiveCorrelation=${correlation?.correlationId}`);

    return this.navigate({
      pathname: `/`,
      search: searchParams.toString(),
    });
  }

  private async determineProgressView(): Promise<void> {
    try {
      const processInstance =
        this.props.router.location.state?.processInstance ||
        (await this.engineService.getProcessInstance(this.currentCorrelationId, this.currentProcessInstanceId));
      if (processInstance?.metadata?.progressView?.toLowerCase() !== 'correlation') {
        if (this.state.progressViewTransition != true) {
          this.setState({ progressViewTransition: true });
        }
      } else {
        if (this.state.progressViewTransition != false) {
          this.setState({ progressViewTransition: false });
        }
      }
    } catch (error) {
      this.setState({ error: error, isLoading: false, showBackdrop: false });
    }
  }

  private async getNextTask(nextTaskMessage?: Messages.EventMessage): Promise<void> {
    this.getNextTaskInProgress = true;
    try {
      const setTask = (task: AnyTaskType | Messages.EventMessage) => {
        this.getNextTaskCallRequired = false;
        this.setState({ nextTask: task }, () => {
          this.taskFinished = false;
          this.props.router.navigate(
            this.props.router.location.pathname
              .replace(this.state.currentTask.flowNodeInstanceId, this.state.nextTask.flowNodeInstanceId)
              .replace(this.state.currentTask.processInstanceId, this.state.nextTask.processInstanceId),
            {
              state: {
                ...(this.props.router.location.state?.action === 'processStarted' && {
                  action: 'processStarted',
                  correlationId: this.currentCorrelationId,
                }),
              },
            }
          );
        });
      };

      if (nextTaskMessage) {
        setTask(nextTaskMessage);
      } else {
        const tasks = await this.engineService.getTasksInCorrelation(this.currentCorrelationId);
        if (tasks?.length) {
          setTask(tasks[0]);
        }
      }
    } catch (error) {
      this.setState({ error: error, isLoading: false, showBackdrop: false });
    } finally {
      if (this.getNextTaskCallRequired) {
        this.getNextTaskCallRequired = false;
        this.getNextTask();
      }
      this.getNextTaskInProgress = false;
    }
  }

  private navigate(path: To, state: Record<string, unknown> = {}): void {
    const stateToUse = {
      loadingSpinnerActive: this.loadingSpinnerRef.current != null,
      ...state,
    };

    this.props.router.navigate(path, { state: stateToUse });
  }
}

export const TaskViewWithRouter = withTranslation()(withRouter(withEngineService(TaskViewWrapper)));
